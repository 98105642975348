.App {
  text-align: center;
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/* 
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
} */

.navbar_wrapper a {
  padding-left: 30px;
}

.dropdown_button a {
  margin: 8px;
}

.navBarContainer {
  display: flex;
  flex-direction: row;
  margin: 20px;
}

.navBarItem {
  margin: 8px;
}

body {
  white-space: nowrap;
  background-color: black;
}

.video {
  height: 100%;
  width: 100%;
  background-size: auto;
}

.clients-container {
  height: 100%;
  width: 100%;
  background-size: auto;
}

.main-header {
  color: white;
  margin: 32px;
}

.sub-header {
  color: white;
  margin: 16px;
}

.drop-down {
  margin: 32px;
  background-color: gold;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}


.video-container {
  margin: 32px;
  margin-left: auto;
  margin-right: auto;
  height: 1920px;
  width: 1080px;
}

.video-grid {
  margin: 32px;
}

.video-one {
  margin: 16px;
}

.video-two {
  margin: 16px;
}

.video-three {
  margin: 16px;
}

.image-grid-item {
  margin: 32px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
